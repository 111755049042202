<template>
    <div id="offers">
      <div class="container-fluid">
        <div class="add-section">
          <b-modal id="discount-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('nav.discount')" @ok="submit">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <label for="">{{ $t('percentage') }}</label>
                <b-form-input
                  type="text"
                  v-model="payload.percentage"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('start-data') }}</label>
                <b-form-input
                  type="date"
                  v-model="payload.start_date"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('end-data') }}</label>
                <b-form-input
                  type="date"
                  v-model="payload.end_date"
                  required
                ></b-form-input>
              </div>

              <div class="feild" v-if="is_show_discription">
                <label for="">{{ $t('ar-dsx') }}</label>
                <b-form-textarea
                  v-model="payload.ar_description"
                  required
                ></b-form-textarea>
              </div>
              <div class="feild" v-if="is_show_discription">
                <label for="">{{ $t('en-dsx') }}</label>
                <b-form-textarea
                  v-model="payload.en_description"
                  required
                ></b-form-textarea>
              </div>
              <div class="feild" v-if="is_show_discription">
                <label for="">{{ $t('kr-dsx') }}</label>
                <b-form-textarea
                  v-model="payload.kr_description"
                  required
                ></b-form-textarea>
              </div>
              <div class="controller py-2 d-flex justify-content-center">
                <b-button variant="outline-primary" @click="is_show_discription = !is_show_discription">
                  <span v-if="!is_show_discription">{{ $t('show-dsx') }}</span>
                  <span v-else>{{ $t('hide-dsx') }}</span>
                </b-button>
              </div>
            </b-form>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <!-- <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-form-input
                  type="text"
                  placeholder="Filter By Name"
                  v-model="filterData.name"
                  required
                ></b-form-input>
              </div>
            </template>
          </iq-card>
        </div> -->
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('nav.discount') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="addInit" size="sm">{{ $t('new-discount') }}</b-button>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col cols="4" v-for="(discount,index) in discounts.data" :key="index">
                    <div class="info border border-primery rounded p-2">
                        <b-row>
                        <b-col cols="4">
                            <div class="icon bg-primary rounded w-100 h-100 d-flex align-items-center justify-content-center" style="font-size: 25px;">
                                {{ discount.percentage }}
                                <i class="fas fa-percentage"></i>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <h4>
                                {{ discount.percentage }}
                            </h4>
                            <div class="date">
                                <span style="font-size: 14px;color: #ccc;">
                                    {{ discount.start_date }} - {{ discount.end_date }}
                                </span>
                            </div>
                            <p>
                                {{ discount.en_description }}
                            </p>
                            <div class="controller">
                                <b-button variant=" iq-bg-danger"  size="md" @click="remove(discount.id)"><i class="ri-delete-bin-line p-0"></i></b-button>
                            </div>
                        </b-col>
                    </b-row>
                    </div>
                    <!-- <div class="offer-card p-2 border rounded">
                        <img :src="offer.image_path" style="max-height: 180px; width: 100%; object-fit: cover;" class="img-fluid rounded mb-3" alt="">
                        <div class="title d-flex align-items-center justify-content-between">
                            <h6 class="font-weight-bold">{{ offer.en_title }}</h6>
                            <b-badge variant="primary">
                                {{offer.percentage}}
                                <i class="fas fa-percentage"></i>
                            </b-badge>
                        </div>
                        <ul class="list-unstyled text-primary">
                            <li>{{ offer.start_date }} - {{ offer.end_date }}</li>
                        </ul>
                        <p class="" style="font-size: 14px;">{{ offer.en_description }}</p>
                        <div class="controller">
                            <b-row>
                                <b-col cols="6">
                                    <b-button  class="w-100" variant=" iq-bg-success" @click="update(offer.id)"  size="md"><i class="ri-ball-pen-fill p-0"> Edit </i></b-button>
                                </b-col>
                                <b-col cols="6">

                                </b-col>
                            </b-row>
                        </div>
                    </div> -->
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="discounts.total"
                  :per-page="discounts.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'offers',
  data () {
    return {
      is_show_discription: false,
      filterData: {
        page: 1
      },
      payload: {
        start_date: '',
        end_date: '',
        percentage: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Name',
          key: 'en_name'
        },
        {
          label: 'Created Date',
          key: 'created_at'
        },
        {
          label: 'Created By',
          key: 'created_by.name'
        },
        {
          label: 'Updated Date',
          key: 'updated_at'
        },
        {
          label: 'Updated By',
          key: 'updated_by.name'
        },
        {
          label: 'Actions',
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getDiscounts(this.filterData)
    },
    addInit () {
      this.$bvModal.show('discount-modal')
    },
    update (id) {
      this.$router.push(`/add-offers/${id}`)
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.addDiscounts(this.payload).then(() => {
        this.$bvModal.hide('discount-modal')
        this.payload = {
          start_date: '',
          end_date: '',
          percentage: ''
        }
        this.getDiscounts(this.filterData)
      })
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeDiscounts(id).then(() => {
          this.getDiscounts(this.filterData)
        })
      })
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getDiscounts(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getDiscounts()
  }
}
</script>
